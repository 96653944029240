import React from "react";
import { Link, graphql } from "gatsby";
// import { getMdxProps } from "gatsby-theme-hypercore/src/templates/page";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import { Container } from "@hyperobjekt/material-ui-website";
import Block from "gatsby-theme-hypersite/block";
import Layout from "gatsby-theme-hypersite/src/layout";
import {
	Carousel,
	PageHero,
	TrackerCard,
	HomeCardGeneric
} from "../components";
import updates from "../../content/updates/updates.json";

const HomePage = ({ classes, ...props }) => {
	const { frontmatter } = props.data.mdx;
	const trackerNodes = props.data.allTrackers.nodes;
	const activeTrackers = [...trackerNodes.filter(n => n.status === "active")];
	const inactiveTrackers = [
		...trackerNodes.filter(n => n.status === "inactive")
	];

	return (
		<Layout heroProps={{ classes: { content: classes.content } }} {...props}>
			<Box>
				{activeTrackers.map((tracker, i) => (
					<TrackerCard key={i} index={i} {...tracker} />
				))}
			</Box>
			<Box>
				<HomeCardGeneric
					name="Why this Work Matters"
					description="We have partnered with Duke & Duck, a creative content studio located in DC to help
		  us start storytelling in a new way."
					video="https://www.youtube.com/embed/nuwuuPn_RZ4"
					slug="about/why-this-matters"
					buttonText="Learn more about why it matters"
				/>
			</Box>
			{updates.updates ? <Carousel slides={updates.updates} /> : null}
			<Box>
				{inactiveTrackers.map((tracker, i) => (
					<TrackerCard key={i} index={i} {...tracker} />
				))}
			</Box>
			<Card className={classes.card}>
				<Container>
					<Grid container spacing={0}>
						<Grid item xs={12} lg={8}>
							<Container>
								<CardContent>
									<Typography component="h2" variant="subtitle1" gutterBottom>
										About
									</Typography>
									<Typography component="p" variant="h3" gutterBottom>
										To date, we have collected over thirty million records from
										thirty states filed since 2016.
									</Typography>
									<CardActions>
										<Link to="/about">
											<Button role="presentation" tabIndex={-1} variant="text">
												Read more
											</Button>
										</Link>
									</CardActions>
								</CardContent>
							</Container>
						</Grid>
						<Grid item lg={4}>
							<CardMedia
								component="img"
								image="/images/card-about.svg"
								alt=""
							/>
						</Grid>
					</Grid>
				</Container>
			</Card>
		</Layout>
	);
};

export default withStyles(theme => ({
	root: {
		paddingTop: 0,
		"& .HypHero-content": {
			maxWidth: "26.5rem",
			marginLeft: 0
		}
	},
	card: {
		maxWidth: "unset",
		backgroundColor: "#0067AC",
		"& *": {
			color: theme.palette.text.light
		},
		"& .MuiCardContent-root": {
			height: "100%",
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "row",
			alignContent: "center"
		},
		"& .MuiCardActions-root": {
			display: "block"
		},
		"& .MuiCardMedia-root": {
			[theme.breakpoints.down("md")]: {
				display: "none"
			}
		},
		"& .MuiCardMedia-media": {
			position: "relative",
			right: theme.spacing(-3)
		}
	},
	content: {
		maxWidth: "25rem",
		marginLeft: 0
	}
}))(HomePage);

export const pageQuery = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			slug
			frontmatter {
				hero
				name
				meta {
					title
					description
					keywords
				}
				store {
					key
					value
				}
			}
		}
		allTrackers {
			nodes {
				description
				thumb
				name
				slug
				status
			}
		}
	}
`;
